import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Fragment, useContext, useState } from 'react';
import { Disclosure } from '@headlessui/react';

import Logo from '@/assets/landing/logo.png';
import { PAGE } from '@/router/routes';
import UserContext from '@/context/UserContext';

import { SvgMenuIcon, SvgXIcon } from '@/components/svg';
import { Button } from '@/components/shared/Buttons';
import { LandingMobileMenu } from '@/components/layouts';
import { Loader } from '@/components/shared';

const LandingHeader = ({ isWithButton }: { isWithButton?: boolean }) => {
  const { user, isFetching, logOut } = useContext(UserContext);
  const { pathname, push } = useRouter();
  const [navigation] = useState([
    {
      name: (
        <span className="whitespace-nowrap">
          Rushfit<sup>TM</sup> 2
        </span>
      ),
      href: PAGE.RUSHFIT_TWO,
      current: pathname === PAGE.RUSHFIT_TWO,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          Rushfit<sup>TM</sup>
        </span>
      ),
      href: PAGE.RUSHFIT,
      current: pathname === PAGE.RUSHFIT,
    },
    {
      name: 'Strike',
      href: PAGE.STRIKE,
      current: pathname === PAGE.STRIKE,
    },
    {
      name: 'Bonus Sessions',
      href: PAGE.BONUS_SESSIONS,
      current: pathname === PAGE.BONUS_SESSIONS,
    },
  ]);

  const handleLogout = () => {
    push(PAGE.OPTIONS_SIGNIN);
    logOut();
  };

  return (
    <Disclosure
      as="nav"
      className="absolute left-0 top-0 z-20 flex min-h-[104px] w-full flex-col md:flex-row"
    >
      {({ open }) => {
        if (typeof window !== 'undefined') {
          if (open) {
            document.body.style.overflow = 'hidden';
          } else {
            document.body.style.overflow = 'scroll';
          }
        }
        return (
          <Fragment>
            <div className="z-20 flex flex-1 flex-col items-center justify-between gap-2 p-8 md:flex-row md:items-start md:gap-8 md:px-12">
              <div className="h-full md:flex md:items-center">
                <Link href={PAGE.LANDING} passHref>
                  <div className="h-4 w-full max-w-[316px] cursor-pointer md:h-5 md:max-w-[485px]">
                    <Image
                      src={Logo}
                      alt=""
                      className="w-full max-w-[316px] cursor-pointer object-contain md:h-5 md:max-w-[485px]"
                    />
                  </div>
                </Link>
              </div>
              <div className="hidden h-full gap-16 md:flex md:items-center">
                {navigation.map((item, index) => (
                  <div className="relative" key={`item_${index}_${pathname}`}>
                    <Link href={item.href} passHref>
                      <span
                        className={classNames(
                          item.current ? 'text-brand-button' : 'text-neutrals-white',
                          'cursor-pointer whitespace-nowrap font-stag text-base font-bold'
                        )}
                      >
                        {item.name}
                      </span>
                    </Link>
                    {item.current && (
                      <span className="absolute inset-x-0 -bottom-1 h-0.5 bg-brand-button" />
                    )}
                  </div>
                ))}
              </div>
              {isWithButton && !user ? (
                <div className="flex gap-4">
                  <Link href={PAGE.OPTIONS_SIGNIN} passHref>
                    <div className="hidden md:block">
                      <Button type="button" className="w-[130px] border-brand-primaryHover">
                        {!isFetching ? 'Login' : <Loader size={24} color="#fff" />}
                      </Button>
                    </div>
                  </Link>
                  <Link href={PAGE.OPTIONS_SIGNUP} passHref>
                    <div className="hidden md:block">
                      <Button type="button" className="w-[130px] border-brand-primaryHover">
                        {!isFetching ? 'Get Started' : <Loader size={24} color="#fff" />}
                      </Button>
                    </div>
                  </Link>
                </div>
              ) : (
                <div className="flex gap-4">
                  <Link href={PAGE.HOME} passHref>
                    <div className="hidden md:block">
                      <Button type="button" className="w-[130px] border-brand-primaryHover">
                        {!isFetching ? 'My Account' : <Loader size={24} color="#fff" />}
                      </Button>
                    </div>
                  </Link>

                  <div className="hidden md:block">
                    <Button
                      type="button"
                      className="w-[130px] border-brand-primaryHover"
                      onClick={handleLogout}
                    >
                      {!isFetching ? 'Sign Out' : <Loader size={24} color="#fff" />}
                    </Button>
                  </div>
                </div>
              )}

              {/* Mobile menu open button */}
              <Disclosure.Button className="inline-flex items-center justify-center rounded-md focus:outline-none md:hidden">
                <span className="sr-only">Open main menu</span>
                <span className="h-8 w-8">
                  {open ? <SvgXIcon className="h-8 w-8" /> : <SvgMenuIcon className="h-8 w-8" />}
                </span>
              </Disclosure.Button>
            </div>

            {/* Mobile menu dropdown */}
            <LandingMobileMenu navigation={navigation} />
          </Fragment>
        );
      }}
    </Disclosure>
  );
};

export default LandingHeader;
