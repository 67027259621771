import Image from 'next/image';
import AuthHeader from '@/components/layouts/AuthHeader';

import HeroImage from '@/assets/images/auth-hero.png';

const AuthLayout = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <div className="relative flex h-screen w-screen flex-1 flex-col overflow-hidden">
    <AuthHeader />
    <section className="flex h-screen w-full flex-1 pt-[124px] sm:pt-[106px]">
      <div className="-mt-[124px] hidden w-full flex-col pt-[106px] sm:-mt-[106px] md:flex md:h-screen md:w-1/2">
        <div className="relative h-full w-full">
          <Image src={HeroImage} fill className="object-cover" alt="" />
          <div className="auth-hero-gradient absolute inset-0 flex w-full flex-col items-center justify-end pb-[34px] text-center font-stag text-[46px] font-bold leading-9">
            <div>GET FIT FIGHTING</div>
            <div>WITH GSP</div>
          </div>
        </div>
      </div>
      <div className="auth-container mx-auto w-full max-w-[525px] overflow-y-scroll px-8 md:w-1/2 md:max-w-full md:px-12 lg:pr-36">
        {children}
      </div>
    </section>
  </div>
);

export default AuthLayout;
