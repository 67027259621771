import { useContext } from 'react';
import { useRouter } from 'next/router';
import { Disclosure } from '@headlessui/react';
import classNames from 'classnames';

import UserContext from '@/context/UserContext';
import { PAGE } from '@/router/routes';

import { Button } from '@/components/shared/Buttons';

const LoggedMobileMenu = () => {
  const { logOut } = useContext(UserContext);
  const { pathname, push } = useRouter();

  const handleLogout = () => {
    logOut();
    push(PAGE.OPTIONS_SIGNIN);
  };

  return (
    <Disclosure.Panel className="mt-[-98px] flex h-screen w-full flex-col justify-start pt-[98px] sm:hidden">
      {({ close }) => (
        <div className="py-4">
          <Disclosure.Button
            as="a"
            href={PAGE.HOME}
            className={classNames(
              pathname === PAGE.HOME
                ? 'border-brand-primary text-brand-primary'
                : 'border-transparent hover:border-gray-300',
              'text-neutral-1000 flex h-16 items-center border-l-4 py-4 pl-3 pr-4 font-stag text-2xl font-semibold'
            )}
            aria-current={pathname === PAGE.HOME ? 'page' : undefined}
          >
            <span className="inline-block">Home</span>
          </Disclosure.Button>
          <Disclosure.Button
            as="a"
            href={PAGE.SETTINGS}
            className={classNames(
              pathname === PAGE.SETTINGS
                ? 'border-brand-primary text-brand-primary'
                : 'border-transparent hover:border-gray-300',
              'flex h-16 items-center border-l-4 py-4 pl-3 pr-4 font-stag text-2xl font-semibold'
            )}
            aria-current={pathname === PAGE.SETTINGS ? 'page' : undefined}
          >
            <span className="inline-block">Settings</span>
          </Disclosure.Button>

          <div className="mt-8 px-6">
            <Button variant="secondary" className="w-full" onClick={handleLogout}>
              Sign Out
            </Button>
          </div>
        </div>
      )}
    </Disclosure.Panel>
  );
};

export default LoggedMobileMenu;
