import Link from 'next/link';
import { useRouter } from 'next/router';

import { PAGE } from '@/router/routes';

import LandingFooter from '@/components/layouts/LandingFooter';
import { SvgGeorgesStPierreLogo } from '@/components/svg';
import { Button } from '@/components/shared/Buttons';

export const InfoLayout = ({ children }: { children: React.ReactNode }) => {
  const { back } = useRouter();

  return (
    <div className="relative flex min-h-screen w-screen flex-1 flex-col overflow-hidden bg-black">
      <header className="flex w-full">
        <div className="flex flex-1 flex-col items-center justify-center gap-2 px-8 py-4 sm:flex-row sm:items-start sm:justify-start sm:gap-8 md:px-12 lg:pr-36">
          <Link href={PAGE.LANDING} passHref>
            <span className="py-4">
              <SvgGeorgesStPierreLogo className="h-5 w-full max-w-[485px] cursor-pointer" />
            </span>
          </Link>
          <div className="hidden h-full sm:flex sm:items-center">
            <Button onClick={back}>Back</Button>
          </div>
        </div>
      </header>

      <section className="flex flex-1 flex-col">{children}</section>
      <LandingFooter />
    </div>
  );
};
