import { Fragment, useContext } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { Disclosure } from '@headlessui/react';
import Logo from '@/assets/landing/logo.png';
import { PAGE } from '@/router/routes';
import { Button } from '@/components/shared/Buttons';
import { SvgXIcon, SvgMenuIcon } from '@/components/svg';
import UserContext from '@/context/UserContext';
import AuthMobileMenu from './AuthMobileMenu';

const AuthHeader = ({ className = '' }: { className?: string }) => {
  const { pathname } = useRouter();
  const { user, logOut } = useContext(UserContext);

  return (
    <Disclosure
      as="nav"
      className={classNames('absolute left-0 top-0 z-20 flex w-full flex-col', className)}
    >
      {({ open }) => {
        if (typeof window !== 'undefined') {
          if (open) {
            document.body.style.overflow = 'hidden';
          } else {
            document.body.style.overflow = 'scroll';
          }
        }
        return (
          <Fragment>
            <div className="z-20 flex flex-1 flex-col items-center justify-between gap-2 p-8 sm:flex-row sm:items-center sm:gap-8 md:px-12 lg:pr-36">
              <Link href={PAGE.LANDING} passHref>
                <div className="w-full max-w-[316px] cursor-pointer md:h-5 md:max-w-[485px]">
                  <Image
                    src={Logo}
                    alt=""
                    className="w-full max-w-[316px] cursor-pointer md:h-5 md:max-w-[485px]"
                  />
                </div>
              </Link>
              <div className="hidden flex-row items-center space-x-4 text-base sm:flex">
                <span className="whitespace-nowrap font-avenir text-base">
                  {pathname === PAGE.OPTIONS_SIGNIN
                    ? "Don't have an account?"
                    : 'Already have an account?'}
                </span>

                <Link
                  href={
                    pathname === PAGE.OPTIONS_SIGNIN ? PAGE.OPTIONS_SIGNUP : PAGE.OPTIONS_SIGNIN
                  }
                >
                  <div>
                    <Button>{pathname === PAGE.OPTIONS_SIGNIN ? 'Get Started' : 'Login'}</Button>
                  </div>
                </Link>
              </div>

              {/* Mobile menu open button */}
              <Disclosure.Button className="inline-flex items-center justify-center rounded-md focus:outline-none sm:hidden">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <SvgXIcon className="block h-8 w-8" />
                ) : (
                  <SvgMenuIcon className="block h-8 w-8" />
                )}
              </Disclosure.Button>
            </div>

            {/* Mobile menu dropdown */}
            <AuthMobileMenu />
          </Fragment>
        );
      }}
    </Disclosure>
  );
};

export default AuthHeader;
