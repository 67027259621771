import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { Disclosure } from '@headlessui/react';

import UserContext from '@/context/UserContext';
import { PAGE } from '@/router/routes';

import { Button } from '@/components/shared/Buttons';

const AuthMobileMenu = () => {
  const { pathname } = useRouter();
  const { user, logOut } = useContext(UserContext);

  return (
    <Disclosure.Panel className="-mt-[124px] flex h-screen w-full flex-col justify-start bg-[#121212] pt-[98px] sm:hidden">
      {({ close }) => (
        <div className="flex flex-col items-center py-10 text-base">
          {user ? (
            <Disclosure.Button as="a" href={PAGE.HOME}>
              <Button className="text-sm" onClick={logOut}>
                Log out
              </Button>
            </Disclosure.Button>
          ) : (
            <>
              <p className="mb-6">
                {pathname === PAGE.OPTIONS_SIGNIN
                  ? "Don't have an account?"
                  : 'Already have an account?'}
              </p>

              <Link
                href={pathname === PAGE.OPTIONS_SIGNIN ? PAGE.OPTIONS_SIGNUP : PAGE.OPTIONS_SIGNIN}
              >
                <Disclosure.Button as="a" href={PAGE.HOME}>
                  <Button className="text-sm">
                    {pathname === PAGE.OPTIONS_SIGNIN ? 'Sign up' : 'Login'}
                  </Button>
                </Disclosure.Button>
              </Link>
            </>
          )}
        </div>
      )}
    </Disclosure.Panel>
  );
};

export default AuthMobileMenu;
