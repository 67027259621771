import Header from './Header';
import Footer from './Footer';
import LandingFooter from './LandingFooter';

interface HomeLayoutProps {
  children: React.ReactNode;
  headerClasses?: string;
  footerClasses?: string;
}

const HomeLayout = ({ children, headerClasses, footerClasses }: HomeLayoutProps) => (
  <div className="flex min-h-screen w-screen flex-1 flex-col overflow-hidden">
    <Header className={headerClasses} />
    <section className="b flex h-full flex-1 flex-col">{children}</section>
    <LandingFooter />
  </div>
);

export default HomeLayout;
