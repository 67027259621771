import Link from 'next/link';
import Image from 'next/image';
import { Fragment } from 'react';

import Logo from '@/assets/landing/logo.png';
import { PAGE } from '@/router/routes';

const navigation = {
  products: [
    {
      name: (
        <span className="cursor-pointer">
          Rushfit <sup>TM</sup> 2
        </span>
      ),
      href: PAGE.RUSHFIT_TWO,
    },
    {
      name: (
        <span className="cursor-pointer">
          Rushfit <sup>TM</sup>
        </span>
      ),
      href: PAGE.RUSHFIT,
    },
    { name: 'Strike', href: PAGE.STRIKE },
    { name: 'Bonus Sessions', href: PAGE.BONUS_SESSIONS },
  ],
  legal: [
    { name: 'Help & Support', href: PAGE.CONTACT_AND_SUPPORT },
    { name: 'Privacy Policy', href: PAGE.PRIVACY_POLICY },
    { name: 'Terms of Use', href: PAGE.TERMS },
    { name: 'Cookie Policy', href: PAGE.COOKIE_POLICY },
  ],
};

const LandingFooter = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer className="bg-landing-dark" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div>
        <div className="flex flex-col items-start gap-6 px-6 py-8 sm:flex-row sm:items-center sm:gap-12 lg:gap-20 lg:px-[120px]">
          {/* Logo */}
          <div className="border-r-none flex w-full max-w-full flex-col  items-center border-white/30 p-0 sm:max-w-[184px] sm:border-r sm:py-3 sm:pr-8">
            <div className="w-full max-w-[316px] md:max-w-[485px]">
              <Image
                src={Logo}
                alt=""
                className="mx-auto w-5/12 max-w-[316px] sm:w-full sm:px-2 md:max-w-[485px]"
              />
            </div>

            <h3 className="mt-4 font-stag text-base font-semibold leading-6 sm:text-sm">
              Train like a champion
            </h3>
          </div>

          {/* Links */}
          <div className="flex w-full flex-1 flex-col gap-8 sm:gap-4">
            <ul role="list" className="flex items-center justify-center gap-4 sm:justify-start">
              {navigation.products.map((item, index) => (
                <Link href={item.href} key={`item_${index}_${item.name}`}>
                  <li className="cursor-pointer whitespace-nowrap text-sm font-extrabold leading-6">
                    {item.name}
                  </li>
                </Link>
              ))}
            </ul>
            <ul role="list" className="flex flex-wrap justify-center gap-4 sm:justify-start">
              {navigation.legal.map((item, index) => (
                <Fragment key={`item_${index}_${item.name}`}>
                  <Link href={item.href}>
                    <li className="cursor-pointer items-center whitespace-nowrap text-sm leading-6">
                      {item.name}
                    </li>
                  </Link>
                  <div className="w-[1px] border-r last:hidden" />
                </Fragment>
              ))}
            </ul>
          </div>
        </div>
        <div className="border-t border-white/30 p-6 lg:px-[120px]">
          <p className="text-center text-sm leading-6 sm:text-left">
            Copyright &copy; {year} Rushfit. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default LandingFooter;
