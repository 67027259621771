import Image from 'next/image';
import Link from 'next/link';
import { SvgGeorgesStPierreLogo } from '@/components/svg/';
import BgBanner from '@/assets/landing/program-details-background.png';
import { PAGE } from '@/router/routes';

const NoHeaderLayout = ({ children }: { children: JSX.Element[] | JSX.Element }) => (
  <div className="min-h-screen">
    <div className="absolute inset-0 z-0 mx-auto min-h-screen">
      <Image src={BgBanner} fill className="object-cover" alt="" />
    </div>
    <header className="relative flex items-center justify-center px-8 pt-8 pb-[73px] sm:pt-12 md:py-[68px]">
      <Link href={PAGE.OPTIONS_SIGNIN}>
        <span>
          <SvgGeorgesStPierreLogo className="h-5 w-full max-w-[485px] cursor-pointer" />
        </span>
      </Link>
    </header>
    <section className="relative mx-6">
      <div className="mx-auto flex max-w-[616px] flex-col justify-center">{children}</div>
    </section>
  </div>
);

export default NoHeaderLayout;
