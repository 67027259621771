import Link from 'next/link';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Disclosure } from '@headlessui/react';
import { useContext } from 'react';

import { PAGE } from '@/router/routes';
import UserContext from '@/context/UserContext';
import { Button } from '@/components/shared/Buttons';
import { Loader } from '@/components/shared';

interface LandingMobileMenuProps {
  navigation: {
    name: string | JSX.Element;
    href: string;
    current: boolean;
  }[];
}

const LandingMobileMenu = ({ navigation }: LandingMobileMenuProps) => {
  const { user, isFetching, logOut } = useContext(UserContext);
  const { pathname, push } = useRouter();

  const handleLogout = () => {
    push(PAGE.OPTIONS_SIGNIN);
    logOut();
  };

  return (
    <Disclosure.Panel className="-mt-[124px] flex h-screen w-full flex-col justify-start bg-[#121212] pt-[98px] md:hidden">
      {({ close }) => (
        <div className="no-scrollbar overflow-y-auto py-10">
          <div className="flex flex-col items-center text-base">
            <div className="mb-12 flex flex-col items-center gap-10">
              {navigation.map((item, index) => (
                <div className="relative" key={`item_${index}_${pathname}`}>
                  <Link href={item.href} passHref>
                    <span
                      className={classNames(
                        item.current ? 'text-brand-button' : 'text-neutrals-white',
                        'cursor-pointer whitespace-nowrap font-stag text-2xl font-bold'
                      )}
                    >
                      {item.name}
                    </span>
                  </Link>
                  {item.current && (
                    <span className="absolute inset-x-0 -bottom-0 h-0.5 bg-brand-button" />
                  )}
                </div>
              ))}
            </div>
            {!user ? (
              <div className="flex flex-col gap-4">
                <Link href={PAGE.OPTIONS_SIGNIN} passHref>
                  <div>
                    <Button type="button" className="w-[177px] border-brand-primaryHover">
                      {!isFetching ? 'Login' : <Loader size={24} color="#fff" />}
                    </Button>
                  </div>
                </Link>
                <Link href={PAGE.OPTIONS_SIGNUP} passHref>
                  <div>
                    <Button type="button" className="w-[177px] border-brand-primaryHover">
                      {!isFetching ? 'Get Started' : <Loader size={24} color="#fff" />}
                    </Button>
                  </div>
                </Link>
              </div>
            ) : (
              <div className="flex flex-col gap-4">
                <Link href={PAGE.HOME} passHref>
                  <div>
                    <Button type="button" className="w-[177px] border-brand-primaryHover">
                      {!isFetching ? 'My Account' : <Loader size={24} color="#fff" />}
                    </Button>
                  </div>
                </Link>

                <div>
                  <Button
                    type="button"
                    className="w-[177px] border-brand-primaryHover"
                    onClick={handleLogout}
                  >
                    {!isFetching ? 'Sign Out' : <Loader size={24} color="#fff" />}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Disclosure.Panel>
  );
};

export default LandingMobileMenu;
