import Link from 'next/link';
import Image from 'next/image';
import { Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import { Disclosure } from '@headlessui/react';
import classNames from 'classnames';
import Logo from '@/assets/landing/logo.png';
import { PAGE } from '@/router/routes';
import { SvgXIcon, SvgMenuIcon } from '@/components/svg';
import { SvgGeorgesStPierreLogo, SvgUserIcon } from '@/components/svg/';
import LoggedMobileMenu from './LoggedMobileMenu';

const Header = ({ className = 'custom-header-shadow' }: { className?: string }) => {
  const { pathname } = useRouter();
  const [navigation, setNavigation] = useState([
    {
      name: 'Home',
      href: PAGE.HOME,
      current: pathname === PAGE.HOME,
    },
  ]);

  return (
    <Disclosure as="nav" className={classNames('flex w-full flex-col', className)}>
      {({ open }) => {
        if (typeof window !== 'undefined') {
          if (open) {
            document.body.style.overflow = 'hidden';
          } else {
            document.body.style.overflow = 'scroll';
          }
        }
        return (
          <Fragment>
            <div className="z-20 flex flex-1 flex-col items-center justify-between gap-2 p-8 sm:flex-row sm:items-start sm:gap-8 md:px-12 lg:pr-36">
              <div className="flex items-center gap-14">
                <Link href={PAGE.HOME} passHref>
                  <div className="w-full max-w-[316px] cursor-pointer md:h-5 md:max-w-[485px]">
                    <Image
                      src={Logo}
                      alt=""
                      className="w-full max-w-[316px] cursor-pointer md:h-5 md:max-w-[485px]"
                    />
                  </div>
                </Link>
                <div className="hidden gap-16 sm:flex">
                  {navigation.map((item, index) => (
                    <Link href={item.href} passHref key={`item_${index}_${item.name}`}>
                      <span
                        className={classNames(
                          item.current ? 'text-brand-primary' : 'text-neutrals-white',
                          'cursor-pointer font-stag text-lg font-bold leading-9'
                        )}
                      >
                        {item.name}
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="hidden sm:flex">
                <Link href={PAGE.SETTINGS}>
                  <div>
                    <SvgUserIcon
                      className={classNames(
                        'cursor-pointer',
                        pathname === PAGE.SETTINGS && 'primary-expand-button'
                      )}
                    />
                  </div>
                </Link>
              </div>
              <div className="flex items-end sm:hidden">
                {/* Mobile menu open button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md focus:outline-none">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <SvgXIcon className="block h-8 w-8" />
                  ) : (
                    <SvgMenuIcon className="block h-8 w-8" />
                  )}
                </Disclosure.Button>
              </div>
            </div>

            {/* Mobile menu dropdown */}
            <LoggedMobileMenu />
          </Fragment>
        );
      }}
    </Disclosure>
  );
};

export default Header;
