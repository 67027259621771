import LandingHeader from '@/components/layouts/LandingHeader';
import LandingFooter from '@/components/layouts/LandingFooter';
import { BlackFridayBanner } from '@/components/shared';

const LandingLayout = ({
  children,
  isWithButton,
}: {
  children: React.ReactNode;
  isWithButton?: boolean;
}) => (
  <>
    <BlackFridayBanner />
    <div className="relative flex min-h-screen w-screen flex-1 flex-col overflow-hidden bg-black">
      <LandingHeader isWithButton={isWithButton} />
      <section className="flex flex-1 flex-col">{children}</section>
      <LandingFooter />
    </div>
  </>
);

export default LandingLayout;
